import theme, { isDark } from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

export const useRoot = makeStyles({
  root: {
    width: '100%',
    minHeight: '100vh',
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: isDark() ? '#212121' : '#F9F9F9',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  rootNoFlex: {
    width: '100%',
    minHeight: '100vh',
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: isDark() ? '#212121' : '#F9F9F9',
  },
});
