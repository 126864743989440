import React from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { Fade } from '@material-ui/core';
import useStyles from './styles';

const AppWrapper = ({ children }) => {
  const styles = useStyles();

  return (
        <main className={styles.content}>
          <Fade
            in
            mountOnEnter
            unmountOnExit
            {...{ timeout: 700 }}
          >
            <div>
              <div className={styles.toolbar} />
              {children}
            </div>
          </Fade>
        </main>
  );
};

AppWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default AppWrapper;
